import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestProcess } from '../../objects/request';
import { ResponseProcess } from '../../objects/response';
import { ProcesswebService } from '../processweb.service';
import { Subject } from 'rxjs';
import { data } from 'jquery';
import { GenericRequestService } from '../generic-request.service';
import { BehaviorSubject } from 'rxjs';
import { AccessService } from '../access.service';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  headerOptions: any = null;
  _isLoggedIn: boolean = false;
  authSub = new Subject<any>();
  userLogged: string;
  userPass: string;
  token: string;
  ip: string;
  private menu$ = new BehaviorSubject<any[]>([]);
  accessUser: any;
  public idperfilLogin: number;


  constructor(
    private _http: HttpClient,
    private _processWebService: ProcesswebService,
    private tokenService: ProcesswebService,
    private _genericRequestService: GenericRequestService,
    private _accessServices: AccessService) {
    this._processWebService.getIp().subscribe(resp => {
      this.ip = resp.ip;
    });
  }


  public _menu$ = () => this.menu$.asObservable();

  login(userObj: any) {
    return new Promise((resp, reject) => {
      let consulta: any = {
        ip: userObj.ip,
        cuenta: userObj.user,
        clave: userObj.upass
      };
      // this._processWebService.getToken().subscribe(token => {
      this._processWebService.execProcessEnd(consulta, '/login-admin', 'post')
        .subscribe((res: ResponseProcess) => {
          if (res && Number(res.idresponse) === 1) {
            const data = res.dataresponse[0];
            sessionStorage.setItem('userCuenta', data.cuenta);
            this._processWebService.setdataUsuario(data.idusuario, data.idfranquicia)
            sessionStorage.setItem('userTempCuenta', data.semillagoogle);
            sessionStorage.setItem('userStatus', data.idestadousuario);
            //sessionStorage.setItem('idPerfilTemp', data.idperfil);

            this._genericRequestService.setInSessionStorage('franchiseId', data.idfranquicia);
            this._genericRequestService.setInSessionStorage('userId', data.idusuario);
            this._genericRequestService.setInSessionStorage('idPerfil', data.idperfil);

            this.idperfilLogin = data.idperfil //this._accessServices.getIdProfile();              
            this._accessServices.setIdProfile(data.idperfil);
            this._genericRequestService.setInSessionStorage('idProveedor', data.idproveedor);
            //Consult the view accesos_v to get menus from perfil logged,
            this.consultMenus(data.idperfil);
            resp(data);
            this.setTipoUser(data.idusuario)
            //TODO this.getLogo();
          } else {
            reject(res);
          }
        });
      // });

    });
  }

  /**
   * Consulta el logo de la franquicia
   */
  getLogo() {
    this._processWebService.getToken().subscribe(response => {
      this._processWebService.getLogo(response.access_token).subscribe(res => {
        if (res.status) {
          if (res.response.logo_admin) {
            sessionStorage.setItem('logo', res.response.logo_admin);
          }
        }
      });
    });
  }

  /**
   * Consult Menus from Perfil
   */
  consultMenus(idperfil: number) {
    var id = (idperfil === this.idperfilLogin) ? this.idperfilLogin : idperfil;
    const datos = {
      'accion': 1,
      'schema': 'plataforma',
      'tabla': 'accesos_v',
      'campo': 'MODULO',
      "ip": this.ip,
      'condicion': "estado = 'ACTIVO' and  idperfil=" + id
    }
    this._accessServices.processGeneric(datos)
      .then((res: any) => {
        var menus = res.dataresponse[0];
        // let menusUsuario = JSON.parse(menus);
        this.orderAccess(menus.modulo);
        //sessionStorage.setItem('accessUser',JSON.stringify(menusUsuario[0].modulo) );
      })
      .catch(error => console.log(error));
  }

  orderAccess(access: any) {

    var accessOrder = [];
    var accessFinal = [];
    var tamañoAcceso = access.length
    access.forEach(element => {
      accessOrder.push(element.modulo)
    });

    accessOrder.sort()

    for (var i = 0; i < accessOrder.length; i++) {
      for (var x = 0; x < tamañoAcceso; x++) {
        if (access[x].modulo != accessOrder[i]) continue;
        accessFinal.push(access[x])
      }
    }
    sessionStorage.removeItem('accessUser');
    sessionStorage.setItem('accessUser', JSON.stringify(accessFinal));
    this.menu$.next(accessFinal);
  }

  //TODO
  loginAuth(userObj: any) {

    if (userObj.authcode) {
      this.headerOptions = new HttpHeaders({
        'x-tfa': userObj.authcode
      });
    }

    const tfasecret = sessionStorage.getItem('userTempCuenta');
    return this._http.post(`${environment.endpointApi}` + 'login-auth', { tfasecret }, {
      observe: 'response',
      headers: this.headerOptions
    });
  }

  changePassword(data) {
    return new Promise((resp, reject) => {
      let idtipouser = sessionStorage.getItem('idtipouser');
      let userCuenta = sessionStorage.getItem('userCuenta');
      let consulta: any = {
        ip: this.ip,
        idusuario: data.idusuario,
        clave: data.pass,
        claveconfirma: data.passconfirma,
        idtipouser: idtipouser,
        cuenta: userCuenta
      };
      // let peticion = new RequestProcess()
      // peticion.proceso = '4'
      // peticion.consulta = JSON.stringify(consulta)
      // this._processWebService.getToken()
      //   .subscribe(token => {
      this._processWebService.execProcessEnd(consulta, '/change-pwd-admin', 'put')
        .subscribe((res: ResponseProcess) => {
          if (res && res.idresponse == '1') {
            //TODO comento porque no encuentro donde se usa en el resto
            // sessionStorage.setItem('User', res.dataresponse);
            resp(res);
          } else {
            reject(res);
          }
        });
      // });
    });
  }

  recoveryPassword(data) {
    return new Promise((resp, reject) => {
      let consulta: any = {
        ip: this.ip,
        cuenta: data.idusuario
      };
      // let peticion = new RequestProcess()
      // peticion.proceso = '3'
      // peticion.consulta = JSON.stringify(consulta)      
      // this._processWebService.getToken()
      //   .subscribe(token => {
      this._processWebService.execProcessEnd(consulta, '/recovery-admin', 'post')
        .subscribe((res: any) => {
          if (res.idresponse === 1) {
            // sessionStorage.setItem('User', res.response['dataresponse']);
            const dataUser = res.dataresponse;
            console.log(dataUser);
            let consultaFranquicia: any = {
              accion:1,
              schema: 'plataforma',
              tabla: 'navegacion_v',
              campo: 'idfranquicia',
              condicion: "cuenta='" + dataUser.cuenta + "'",
              idusuario: dataUser.idusuario
            };
            this._accessServices.processGenericNew(consultaFranquicia, '/in-table-admin')
              .then((responseFranchise: any) => {
                console.log(responseFranchise);
                if (responseFranchise.idresponse === 1) {
                  let data = responseFranchise.dataresponse;
                  if (data[0].idfranquicia) {
                    const dataEmail = { 
                      email: dataUser.correo,
                      usuario: dataUser.nombre,
                      cuenta: dataUser.cuenta,
                      clave: dataUser.clave,
                      idfranquicia: data[0].idfranquicia
                    }
                    // Envío email para recuperar clave
                    try {
                      this._processWebService.sendEmailGeneric(dataEmail, 'trantec/recoveryPassword')
                        .subscribe(respuesta => {

                          console.log(respuesta);
                          if (respuesta.success) {
                            resp(respuesta.message);
                          } else {
                            reject(respuesta.error);
                          }
                        });
                    } catch (error) {
                      reject(error);
                    }
                  } else {
                    reject('No se encontro la franquicia');
                  }
                }
              });
          } else {
            reject(res.response);
          }
        });
      // });
    });
  }

  updateApiKey(data) {
    return new Promise((resp, reject) => {
      data.accion = 2;//Update
      data.idusuario = "10001";
      data.ip = this.ip;
      data.schema = "admin";
      data.tabla = "usuarios";
      //idtipoautenticacion=2 --> Google  idtipoautenticacion=3 --> Whatsapp
      data.campo = "idtipoautenticacion='2',semillagoogle='" + data.autenticacion + "'";
      data.condicion = "cuenta ='" + data.cuenta + "'";
      this._accessServices.processGeneric(data)
        .then((res: any) => {
          if (res.idresponse === 1) {
            resp(true);
          } else {
            reject(res.response);
          }
        })
        .catch(error => console.log(error));
      let consulta: any = {
        'datos': data
      };
      // let peticion = new RequestProcess()
      // peticion.proceso = '5'
      // peticion.consulta = JSON.stringify(consulta)      

      // this._processWebService.getToken()
      //   .subscribe(token => this._processWebService.execProcess(consulta, '5', token.access_token)
      //     .subscribe((res: ResponseProcess) => {
      //       if (res.response['idresponse'] === 1) {
      //         resp(true);
      //       } else {
      //         reject(res.response);
      //       }
      //     }));
    });
  }

  setupAuth() {
    const cuenta = sessionStorage.getItem('userCuenta');
    return this._http.post(`${environment.endpointApi}` + 'tfa/setup', { uname: cuenta }, { observe: 'response' });
  }

  updateAuthStatus(value: boolean) {
    this._isLoggedIn = value;
    this.authSub.next(this._isLoggedIn);
    sessionStorage.setItem('isLoggedIn', value ? 'true' : 'false');
  }

  getAuthStatus() {
    this._isLoggedIn = sessionStorage.getItem('isLoggedIn') == 'true' ? true : false;
    return this._isLoggedIn;
  }



  logoutUser() {
    sessionStorage.setItem('isLoggedIn', "false")
    sessionStorage.removeItem('usercuenta')
    sessionStorage.removeItem('idtipouser')
    sessionStorage.removeItem('idusuario')
    sessionStorage.removeItem('User')
    sessionStorage.removeItem('usercuenta')
    sessionStorage.removeItem('userStatus')
    sessionStorage.removeItem('userTempCuenta')
    sessionStorage.removeItem('idFranquicia')
    sessionStorage.removeItem('userCuenta')
    sessionStorage.removeItem('accessUser')
    sessionStorage.removeItem('franchiseId')
    sessionStorage.removeItem('userId')
    sessionStorage.removeItem('idPerfil')
    sessionStorage.removeItem('isLoggedIn')
    this._isLoggedIn = false;
    //this.authSub.next(this._isLoggedIn);
  }

  getAuth() {
    return this._http.get(`${environment.endpointApi}` + 'tfa/setup', { observe: 'response' });
  }

  deleteAuth() {
    return this._http.delete(`${environment.endpointApi}` + 'tfa/setup', { observe: 'response' });
  }

  verifyAuth(token: any) {
    const key = sessionStorage.getItem('api-key');
    return this._http.post(`${environment.endpointApi}` + 'tfa/verify', { token, key }, { observe: 'response' });
  }


  setTipoUser(idUser: string) {

    let idFranquicia = sessionStorage.getItem('idFranquicia');

    // this.tokenService.getToken().subscribe(response => {

    this.tokenService.getUsuariosAdmin(idFranquicia).subscribe(res => {
      console.log(res);
      let usuarios = res.idresponse === 1 ? res.dataresponse : [];
      let userFilter = usuarios.filter((element: any) => {
        if (element.idusuario === idUser) {
          return element.idtipousuario
        }
      });
      console.log(userFilter);
      sessionStorage.setItem('idtipouser', userFilter && userFilter.length > 0 ? userFilter[0].idtipousuario : "")
    });

    // });

  }
}
