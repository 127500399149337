import { Component, ViewEncapsulation, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { Router } from '@angular/router';
import { sha512 } from 'js-sha512';
import Keyboard from 'simple-keyboard';
import { AlertConfig } from 'ngx-bootstrap/alert';
import { LoginServiceService } from '../../services/login-service/login-service.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

import layout from './layouts/spanish';
import { GenericRequestService } from '../../services/generic-request.service';
import { SSL_OP_NO_SESSION_RESUMPTION_ON_RENEGOTIATION } from 'constants';
export interface FormModel {
  captcha?: string;
}

export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: 'success' });
}
@Component({
  selector: 'app-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'login.component.html',
  styleUrls: [
    '../../../../node_modules/simple-keyboard/build/css/index.css',
    './login.component.css'
  ],
  providers: [{ provide: AlertConfig, useFactory: getAlertConfig }]
})
export class LoginComponent implements OnInit {

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  @ViewChild('myModalResponse') public myModalResponse: ModalDirective;

  value = ''; // value for keyboard
  upass: string = '';
  keyboard: Keyboard;
  dismissible = true;
  tfaFlag: boolean = false;
  showAlert: boolean = false;
  viewKeyboard: Boolean = false;
  LoginForm: FormGroup;
  userObject: any = {
    user: '',
    upass: '',
    ip: ''
  };
  alertsDismiss: any = [];
  errorMessage: string;
  titulo = ''
  mensaje = ''
  ip = '';
  completForm = true;

  constructor(private _loginService: LoginServiceService,
    public _formBuilder: FormBuilder,
    private _router: Router,
    private _genericRequestService: GenericRequestService,) {
    // this._loginService.login();
    this._genericRequestService.getIp().subscribe((resp: any) => {
      this.ip = resp.ip;
    })
  }

  ngOnInit() {
    this.LoginForm = this._formBuilder.group({
      // recaptchaReactive: this.recaptchaReactive,
      user: new FormControl('', [Validators.required]),
      password: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.pattern(/[A-Za-z0-9,;._%\-+*/=))&%$#"!)]{5,}$/)
      ]),
      authcode: new FormControl('', [Validators.required]),
      recaptchaReactive: new FormControl('', Validators.required)
    });

    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      layout: layout
    });
    this.userObject = {};
  }

  loginUser() {
    this.userObject.upass = sha512(this.LoginForm.get('password').value);
    this.userObject.user = this.LoginForm.get('user').value;
    this.userObject.ip = this.ip;
    const captcha = this.LoginForm.get('recaptchaReactive').value;
    this.userObject.authcode = this.LoginForm.get('authcode').value ? this.LoginForm.get('authcode').value : null;
    if (captcha) {
      if (this.tfaFlag) {
        this._loginService.loginAuth(this.userObject)
          .subscribe((data) => {
            let msg = ""
            switch (data.body['status']) {
              case 200:
                this._loginService.updateAuthStatus(true);
                const statusUser = sessionStorage.getItem('userStatus')
                if (statusUser == '2') {
                  this._router.navigateByUrl('/cambio-clave');
                } else {
                  this._router.navigateByUrl('/home');
                }

                break;
              case 206:
                msg = data.body['message']
                this.showModal('Error', msg)
                break;
              case 403:
                msg = data.body['message']
                this.showModal('Error', msg)
                break;
              case 404:
                msg = data.body['message']
                this.showModal('Error', msg)
                break;
              default:
                break;
            }
          });
      } else {
        // Detecta si es primera vez que ingresa, para generar QR de auth.
        this._loginService.login(this.userObject)
          .then((res: any) => {
            if (res.semillagoogle == null) {
              this._loginService.updateAuthStatus(true);
              this._router.navigateByUrl('/authenticator');
            } else {
              this.tfaFlag = true;
            }
          })
          .catch(err => {
            console.log(err);
            // cod 80 -> actualizar api-key
            if (err.idresponse == 80 || err.idresponse == 77) {
              sessionStorage.setItem('userCuenta', this.userObject.user);
              let datauser = JSON.parse(err.dataresponse)
              this._genericRequestService.setInSessionStorage('userId', datauser.idusuario);
              //this._loginService.setTipoUser(datauser.idusuario);
              sessionStorage.setItem('idtipouser', datauser.idtipousuario);
              this._genericRequestService.setInSessionStorage('idPerfil', datauser.idperfil);
              this._loginService.consultMenus(datauser.idperfil);
              sessionStorage.setItem('idFranquicia', datauser.idfranquicia);

              this._loginService.updateAuthStatus(true);
              this._router.navigateByUrl('/authenticator');
            } else {
              const msg = err.response;
              this.showModal('Error', msg)
            }
          })
      }

    } else {
      this.showModal('Error', `Debe resolver el captcha para continuar`)
    }
  }

  onChange = (input: string) => {
    this.value = input;
    this.completForm = this.LoginForm.get('user').hasError('required') && this.LoginForm.get('password').hasError('required');
    if (input === '') {
      this.completForm = true;
      this.LoginForm.get('password').markAsDirty({ onlySelf: true });
    } else if (input !== '') {
      this.LoginForm.get('password').setValue(input);
      this.LoginForm.get('password').markAsPristine({ onlySelf: true });
    }
  }

  onKeyPress = (button: string) => {
    // tslint:disable-next-line: curly
    if (button === '{shift}' || button === '{lock}') this.handleShift();
  }

  handleShift = () => {
    const currentLayout = this.keyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  }

  showModal(titulo: string, mensaje: string) {
    this.titulo = titulo;
    this.mensaje = mensaje;
    this.myModalResponse.show();
  }

}
