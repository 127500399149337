export const environment = {
  ESB_ENDPOINT: 'https://dev-bus.yopresto.co/',
  endpointTransactional: 'https://dev-bus.yopresto.co/api/web/v1.0.0',
  webAdminApi: 'https://api.trantec-dev.com',
  token: 'https://dev-bus.yopresto.co/token',  
  // endpointApi: 'https://dev-api.yopresto.co/',
  // endpointApi: 'http://localhost:3000/',
  endpointApi: 'https://yp-api.trantec-dev.com/',
  contenido: 'https://dev-bus.yopresto.co/api/admincontenido/v1.0.0', 
  production: false,
  error_sistema: 'Se genero un error en el sistema',
  key_token: 'RThqSDYzeXl4OWNJX3hqSlZESkxIQm1pWWpZYTpNM3VoTDVnVEczVHFta1FxVmRJc0d6bDU4UnNh',
  endpoint_ip: 'https://api.ipify.org/?format=json',
  contenidoUpdate: 'https://dev-bus.yopresto.co/api/admincontenido/registro/v1.0.0',
  contenidoDelete: 'https://dev-bus.yopresto.co/api/admincontenido/eliminar/v1.0.0',

  endpoint_platform_transactional: 'https://dev-portal.yopresto.co/',
  URL_EMAIL_VERIFY_DOCUMENT: 'mail/verifyDocument',
  URL_EMAIL_VERIFY_DOCUMENT_APROVED: 'mail/verifyDocumentAprobados',
  URL_EMAIL_ALLY_ACCOUNT_OPEN: 'mail/openAlly',
  URL_EMAIL_ALLY_ACCOUNT_OPEN_SERVICE_CLIENT: 'mail/openallyserviciocliente',
  
  URL_LEYPAL_UPLOAD_FILE: 'api/upload-file-leypal/v1.0.0'
};
