import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipePrb'
})
export class PipePrbPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    if(value === 1){
      return 'ACTIVO'
    }else if(value === 2)
      {
        return 'INACTIVO';
      }    
  }
}
