import {INavData} from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Theme'
  },
  {
    name: 'Colors',
    url: '/theme/colors',
    icon: 'icon-speedometer'
  },
  {
    name: 'Typography',
    url: '/theme/typography',
    icon: 'icon-pencil'
  },
  {
    title: true,
    name: 'Components'
  },
  {
    name: 'Base',
    url: '/base',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Cards',
        url: '/base/cards',
        icon: 'icon-puzzle'
      },
      {
        name: 'Carousels',
        url: '/base/carousels',
        icon: 'icon-puzzle'
      },
      {
        name: 'Collapses',
        url: '/base/collapses',
        icon: 'icon-puzzle'
      },
      {
        name: 'Forms',
        url: '/base/forms',
        icon: 'icon-puzzle'
      },
      {
        name: 'Navbars',
        url: '/base/navbars',
        icon: 'icon-puzzle'

      },
      {
        name: 'Pagination',
        url: '/base/paginations',
        icon: 'icon-puzzle'
      },
      {
        name: 'Popovers',
        url: '/base/popovers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Progress',
        url: '/base/progress',
        icon: 'icon-puzzle'
      },
      {
        name: 'Switches',
        url: '/base/switches',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tables',
        url: '/base/tables',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tabs',
        url: '/base/tabs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Buttons',
    url: '/buttons',
    icon: 'icon-cursor',
    children: [
      {
        name: 'Buttons',
        url: '/buttons/buttons',
        icon: 'icon-cursor'
      },
      {
        name: 'Dropdowns',
        url: '/buttons/dropdowns',
        icon: 'icon-cursor'
      },
      {
        name: 'Brand Buttons',
        url: '/buttons/brand-buttons',
        icon: 'icon-cursor'
      }
    ]
  },
  {
    name: 'Charts',
    url: '/charts',
    icon: 'icon-pie-chart'
  },
  {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'CoreUI Icons',
        url: '/icons/coreui-icons',
        icon: 'icon-star',
        badge: {
          variant: 'success',
          text: 'NEW'
        }
      },
      {
        name: 'Flags',
        url: '/icons/flags',
        icon: 'icon-star'
      },
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    children: [
      {
        name: 'Alerts',
        url: '/notifications/alerts',
        icon: 'icon-bell'
      },
      {
        name: 'Badges',
        url: '/notifications/badges',
        icon: 'icon-bell'
      },
      {
        name: 'Modals',
        url: '/notifications/modals',
        icon: 'icon-bell'
      }
    ]
  },
  {
    name: 'Widgets',
    url: '/widgets',
    icon: 'icon-calculator',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Extras',
  },
  {
    name: 'Pages',
    url: '/pages',
    icon: 'icon-star',
    children: [
      {
        name: 'Login',
        url: '/login',
        icon: 'icon-star'
      },
      {
        name: 'Register',
        url: '/register',
        icon: 'icon-star'
      },
      {
        name: 'Error 404',
        url: '/404',
        icon: 'icon-star'
      },
      {
        name: 'Error 500',
        url: '/500',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Disabled',
    url: '/dashboard',
    icon: 'icon-ban',
    badge: {
      variant: 'secondary',
      text: 'NEW'
    },
    attributes: {disabled: true},
  },
  {
    name: 'Download CoreUI',
    url: 'http://coreui.io/angular/',
    icon: 'icon-cloud-download',
    class: 'mt-auto',
    variant: 'success',
    attributes: {target: '_blank', rel: 'noopener'}
  },
  {
    name: 'Try CoreUI PRO',
    url: '/home',
    icon: 'icon-layers',
    variant: 'danger',
    // attributes: { target: '_blank', rel: 'noopener' }
  }
];

export const dataUser = {
  'data': {
    'idpersona': 57,
    'nombre': 'JULIAN CAMILO CORTES DIAZ',
    'documento': '1022941515',
    'estado': 1,
    'nombre_estado': 'ACTIVO',
    'idusuario': parseInt(atob(sessionStorage.getItem('userId'))),
    'cuenta': '1022941515',
    'idperfil': 40,
    'perfil': 'ADMINISTRACION',
    'idestadousuario': 1,
    'estadousuario': 'ACTIVO',
    'modulo': [
      {
        'idmenu': 77,
        'idrol': 1,
        'estadoacceso': 1,
        'modulo': 'CAMBIAR CONTRASENA',
        'icono': 'fa-key',
        'url': '/cambio-clave',
        'fecha': '2018-02-04T15:54:55.742317+00:00',
        'menu': null
      },
      {
        'idmenu': 70,
        'idrol': 1,
        'estadoacceso': 1,
        'modulo': 'GESTION DE ACCESO',
        'icono': 'fa-code-fork',
        'url': '/accesos',
        'fecha': '2019-01-28T22:12:47.68674+00:00',
        'menu': null
      },
      {
        'idmenu': 71,
        'idrol': 4,
        'estadoacceso': 1,
        'modulo': 'GESTION DE USUARIOS',
        'icono': 'fa-user-o',
        'url': '/usuarios/usuarios',
        'fecha': '2019-01-28T23:05:52.859548+00:00',
        'children':
          [
            {
              'idmenu': 74,
              'idrol': 1,
              'estadoacceso': 1,
              'menu': 'CONSULTAR',
              'url': '/accesos'
            },
            {
              'idmenu': 73,
              'idrol': 1,
              'estadoacceso': 1,
              'menu': 'EDITAR',
              'url': '/usuarios'
            },
            {
              'idmenu': 75,
              'idrol': 1,
              'estadoacceso': 1,
              'menu': 'ACTIVAR DESACTIVAR',
              'url': '/usuarios'
            },
            {
              'idmenu': 72,
              'idrol': 1,
              'estadoacceso': 1,
              'menu': 'CREAR',
              'url': '/usuarios'
            }
          ]
      },
      /*      {
             "idmenu": 76,
             "idrol": 1,
             "estadoacceso": 1,
             "modulo": "CATEGORIAS Y GRUPOS",
             "icono": "fa-users",
             "url": "/accesos",
             "fecha": "2019-01-28T23:10:04.262542+00:00",
             "menu": null
           },  */
      {
        'idmenu': 86,
        'idrol': 1,
        'estadoacceso': 1,
        'modulo': 'GESTION DE PERFIL',
        'icono': 'fa-cube',
        'url': '/perfiles',
        'menu': null
      },
      /*       {
              "idmenu": 85,
              "idrol": 1,
              "estadoacceso": 1,
              "modulo": "GESTION DE SECCIONES",
              "icono": "fa-object-group",
              "url": "/accesos",
              "fecha": "2019-02-16T15:45:08.920509+00:00",
              "menu": null
            },  */
      /*    {
           "idmenu": 88,
           "idrol": 1,
           "estadoacceso": 1,
           "modulo": "GESTION DE PLANTILLA",
           "icono": "fa-cubes",
           "url": "/accesos",
           "fecha": "2019-02-25T08:21:20.072659+00:00",
           "menu": null
         },  */
      {
        'idmenu': 89,
        'idrol': 1,
        'estadoacceso': 1,
        'modulo': 'REPORTE',
        'icono': 'fa-book',
        'url': '/accesos/reporte-accesos',
        'fecha': '2019-05-09T17:31:51.265043+00:00',
        'menu': null
      }, {
        'idmenu': 90,
        'idrol': 1,
        'estadoacceso': 1,
        'modulo': 'Productos',
        'icono': 'fa-book',
        'url': '/accesos/configuracion-productos',
        'fecha': '2019-05-09T17:31:51.265043+00:00',
        'menu': [
          {
            'idmenu': 91,
            'idrol': 1,
            'estadoacceso': 1,
            'menu': 'Parametrizacion',
            'url': '/configuracion-productos'
          }, {
            'idmenu': 92,
            'idrol': 1,
            'estadoacceso': 1,
            'menu': 'Configuracion',
            'url': '/configuracion-productos/franquicia'
          }]
      },
      {
        'idmenu': 93,
        'idrol': 1,
        'estadoacceso': 1,
        'modulo': 'Configuracion',
        'icono': 'fa-book',
        'url': '/administrator-configuration/product-list',
        'fecha': '2019-05-09T17:31:51.265043+00:00',
        'menu': null
      }
    ]
  }
};
