import { Injectable, Inject } from '@angular/core';
import { ProcesswebService } from './processweb.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  idPerfil: number;
  idmodul: number;
  modulos: any;
  nombre: string;
  url: string;

  constructor(private processWebService: ProcesswebService, private router: Router) {}

  public setIdProfile(idPerfil: number) {
    this.idPerfil = idPerfil;
  }

  public getIdProfile() {
    return this.idPerfil;
  }

  public setNameProfile(nombre: string) {
    this.nombre = nombre;
  }

  public getNameProfile() {
    return this.nombre;
  }

  public setIdModul(id) {
    this.idmodul = id;
  }

  public getIdModul() {
    return this.idmodul;
  }

  public getProfiles() {
    return new Promise((resp, reject) => {
      const datos = this.processWebService.testRequest().subscribe((datos) => {
        resp(datos);
      });

      // .subscribe((res: any) => {
      // });
      // const peticion = new RequestProcess();
      // peticion.proceso = '1';
      // peticion.identificacion = "";
      // this.accessService.execProcess(peticion, 'consultacontratos')
      // .subscribe((res: any) => {

      // });
    });
  }
  //TODO Eliminar este metodo y usar processGeneric
  public consultGeneric(consulta: any) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken()
      // .subscribe( (token: any) => {
      // console.log(consulta);
      this.processWebService.execProcessEnd(consulta, '/in-table', 'post').subscribe((res: any) => {
        if (res.idresponse === 1) {
          resp(res.dataresponse[0]);
        } else {
          reject(res);
        }
      });
    });
  }

  public processGeneric(consulta: any) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken()
      // .subscribe( (token: any) => {
      this.processWebService.execProcessEnd(consulta, '/in-table-admin', 'post').subscribe((res: any) => {
        if (res.idresponse === 1) {
          resp(res);
        } else {
          reject(res);
        }
      });
    });
    // )})
  }

  public processGenericNew(consulta: any, endpoint: string) {
    return new Promise((resp, reject) => {
      this.processWebService.execProcessEnd(consulta, endpoint, 'post')
      .subscribe((res: any) => {
          if (res.idresponse === 1) {
            resp(res);
          } else {
            reject(res)
          } 
        });
    });
  }

  public consultaFranquicia(consulta: any) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken()
      //   .subscribe((token: any) => {

      const peticion: string = JSON.stringify(consulta);
      this.processWebService.execProcessEnd(peticion, '/in-table', 'post').subscribe((res: any) => {
        if (res.idresponse === 1) {
          resp(res.dataresponse);
        } else {
          reject(res.response);
        }
      });
      // });
    });
  }

  crearPerfil(consulta: Object) {
    return new Promise((resp, reject) => {
      this.processWebService.execProcessEnd(consulta, '/profile-manage-admin', 'post').subscribe((res: any) => {
        if (res.idresponse === 1) {
          resp(res.dataresponse);
        } else {
          reject(res.response);
        }
      });
    });

    /*  const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': `Bearer ` + token,
         'operation': '7'
       })
     }; */
  }

  setModulos(modul) {
    this.modulos = modul;
  }

  getModul() {
    return this.modulos;
  }

  public actualizarTabla(consulta: any) {
    return new Promise((resp, reject) => {
      this.processWebService.execProcessEnd(consulta, '/in-table', 'post').subscribe((res: any) => {
        if (res.idresponse === 1) {
          resp(res.response);
        } else {
          reject(res.response);
          }
      });
    });
  }
}
