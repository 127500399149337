import {Injectable} from '@angular/core';
import {ProcesswebService} from './processweb.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GenericRequestService {

  constructor(protected processWebService: ProcesswebService, protected router: Router) {
  }

  getIp() {
    return this.processWebService.getIp();
  }

  protected genericRequest(body: any, operation?: string) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken()
      //   .subscribe((token: any) => {
            const request: string = JSON.stringify(body);
            console.log(body);
            this.processWebService.execProcessEnd(body, (operation || "/in-table-admin"), "post")
              .subscribe((res: any) => {
                console.log("Config Product");
                console.log(res);
                if (res.idresponse === 1) {
                  resp(res);
                } else {
                  reject(res);
                }
              });
          }
        );
    // });
  }

  protected genericRequestTrx(body: any, operation?: string) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken()
      //   .subscribe((token: any) => {
            const request: string = JSON.stringify(body);
            this.processWebService.execProcessEnd(body, "/in-table", "post")
              .subscribe((res: any) => {
                // console.log("Config Product");
                // console.log(res);
                if (res.idresponse === 1) {
                  resp(res);
                } else {
                  reject(res);
                }
              });
          }
        );
    // });
  }

  public setInSessionStorage(name: string, value: string): void {
    sessionStorage.setItem(name, btoa(value));
  }

  public getFromSessionStorage(name: string) {
    let base64 = sessionStorage.getItem(name);
    return atob(base64);
  }

  public getFranchiseId(): number {
    try {
      let item = this.getFromSessionStorage('franchiseId');
      if (item != undefined && item != '' && parseInt(item) > 0) {
        return parseInt(item);
      } else {
        this.router.navigate(['/login']);
      }
    } catch (e) {
      console.error(e);
      this.router.navigate(['/login']);
    }

  }

  public getUserId(): number {
    try {
      let item = this.getFromSessionStorage('userId');
      if (item != undefined && item != '' && parseInt(item) > 0) {
        return parseInt(item);
      } else {
        this.router.navigate(['/login']);
      }
    } catch (e) {
      console.error(e);
      this.router.navigate(['/login']);
    }

  }

  public requestGenericNew(body: any) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken().subscribe((token: any) => {
        const request: string = JSON.stringify(body);
        this.processWebService.execProcessEnd(request, "/in-table", "post")
          .subscribe((res: any) => {
            if (res.idresponse === 1) {
              resp(res.dataresponse);
            } else {
              reject(res.response);
            }
          });
      // });
    });
  }

  public requestGeneric(body: any, operation?: string) {
    return new Promise((resp, reject) => {
      const request: string = JSON.stringify(body);
      this.processWebService.execProcessEnd(request, (operation || '/in-table'), "post")
        .subscribe((res: any) => {
          console.log(res);
          if (res.idresponse === 1) {
            resp(res.dataresponse);
          } else {
            reject(res);
          }
        });
    });
  }

  protected genericRequestAdmin(body: any) {
    return new Promise((resp, reject) => {
      // this.processWebService.getToken().subscribe((token: any) => {
      // const request: string = body;
      this.processWebService.execProcessEnd(body, '/in-table-admin', 'post')
        .subscribe((res: any) => {
          console.log(res);
          // tslint:disable-next-line:triple-equals
          if (res.idresponse === 1) {
            resp(res.dataresponse);
          } else {
            reject(res.response);
          }
        });
      // });
    });
  }
}
