import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// Modules
import {HttpClientModule} from '@angular/common/http';
import {AngularDualListBoxModule} from 'angular-dual-listbox';

import {PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AppComponent} from './app.component';

// Import containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {RegisterComponent} from './views/register/register.component';
import {AuthenticatorComponent} from './views/authenticator/authenticator.component';
import {RecoveryPasswordComponent} from './views/recovery-pass/recoverypass.component';
import {ChangePasswordComponent} from './views/change-passdword/changepass.component';
import {OtpComponent} from './views/otp/otp.component';
import {ChangePassdwordPreviousComponent} from './views/change-passdword-previous/change-passdword-previous.component';
// Dependency
import {LoginServiceService} from './services/login-service/login-service.service';
import {AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule} from '@coreui/angular';

// Import routing module
import {AppRoutingModule} from './app.routing';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts';
import {AlertModule} from 'ngx-bootstrap/alert';
import {RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {PipePrbPipe} from './pipes/pipe-prb.pipe';
import {ModalModule} from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    AngularDualListBoxModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AlertModule.forRoot(),
    ModalModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    AuthenticatorComponent,
    RecoveryPasswordComponent,
    ChangePasswordComponent,
    OtpComponent,
    ChangePassdwordPreviousComponent,
    PipePrbPipe
  ],
  providers: [
    LoginServiceService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {siteKey: '6LcfhrsZAAAAAExD48qjv0NstNVMtOupiD2M_TBz'} as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
