import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {


  constructor( private http: HttpClient) { }

  getToken() {

    const password = 'password';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${environment.key_token}`,
      })
    };

    const body = new HttpParams()
    .set('grant_type', 'password')
    .set('username', 'yopresto')
    .set('password', 'Y0pr3st0');



    return this.http.post(`${environment.token}`, body, httpOptions);
    }

  }

