import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { LoginServiceService } from '../../services/login-service/login-service.service';


@Component({
  selector: 'app-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.css']
})
export class AuthenticatorComponent implements OnInit {

  tfa: any = {};
  authcode: string = "";
  errorMessage: string = null;

  constructor(private _loginService: LoginServiceService, private _router: Router) {
    //TODO
    this.setup();
  }

  ngOnInit() {
  }

  getAuthDetails() {
    this._loginService.getAuth().subscribe((data) => {
      const result = data.body      
      if (data['status'] === 200) {             
        if (result == null) {
          this.setup();
        } else {
          this.tfa = result;
        }
      }
    });
  }

  setup() {
    //TODO validar servicio de autenticacion
    this._loginService.setupAuth().subscribe((data) => {
      const result:any = data.body
      if (data['status'] === 200) {                
        sessionStorage.setItem('api-key', result.tempSecret)
        this.tfa = result;
      }
    });
  }

  confirm() {
    this._loginService.verifyAuth(this.authcode).subscribe((data) => {
      const result = data.body
      if (result['status'] === 200) {
        // actualizar api-key google en DB
        this.updateAuth(1, sessionStorage.getItem('api-key'));
      } else {
        this.errorMessage = result['message'];
      }
    });
  }

  disabledTfa() {
    this._loginService.deleteAuth().subscribe((data) => {
      const result = data.body
      if (data['status'] === 200) {        
        this.authcode = "";
        this.getAuthDetails();
      }
    });
  }

  /***
   * PROCESO # 5 - ACTUALIZACIÓN DE DOBLE FACTOR
   * 
   * @param tipoautenticacion:integer	1-> google authenticator,  2 -> whatsapp tocken, 	Obligatorio
   * @param autenticacion: string	Api key de google	Obligatorio
   */
  private updateAuth(tipoautenticacion, autenticacion) {

    const datos = {
      cuenta: sessionStorage.getItem('userCuenta'),
      tipoautenticacion,
      autenticacion
    }

    this._loginService.updateApiKey(datos)
      .then( () =>{         
          sessionStorage.removeItem('api-key')
          this._router.navigateByUrl('/cambio-clave')
      }) // eliminar key-api de sessionstorage ??
      .catch( err => console.log(err));

  }

}
